import React from 'react'
import Header from './header'
import Footer from './footer'

import '../styles/normalize.css'
import '../styles/global.css'

class Template extends React.Component {
  render() {
    const { children } = this.props
        
    return (
      <div className="site">
        <Header/>
        <main className="site-content">        
          {children}
        </main>
        <Footer/>
      </div>      
    )
  }
}

export default Template
