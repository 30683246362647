import React from "react";
import { Link } from "gatsby";
import styles from "./header.module.css";
import Logo from "./logo";
import NavDropdownBusiness from "./navDropdownBusiness";
import NavDropdownPersonal from "./navDropdownPersonal";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.toggleBusiness = this.toggleBusiness.bind(this);

    this.togglePersonal = this.togglePersonal.bind(this);
  }

  toggleBusiness = () => {
    const { show } = this.state;
    this.setState({ show: show === "business" ? null : "business" });
  };

  togglePersonal = () => {
    const { show } = this.state;
    this.setState({ show: show === "personal" ? null : "personal" });
  };

  render() {
    return (
      <div className={styles.topNav}>
        <div className="grid">
          <div className="grid-cell">
            <div className={styles.logo}>
              <Logo />
              <div className={styles.mobileCta2}>
                  <a
                      href="https://mohragency.epaypolicy.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button button-small-med button-secondary"
                    >
                        Pay Online
                        </a>
              </div>
              <div className={styles.mobileCta}>
                <Link
                  className="button button-small-med"
                  to={"/get-insurance/"}
                >
                  Get a Quote
                </Link>
              </div>
            </div>
          </div>
          <nav>
            <div className="grid-cell">
              <ul className="list-unstyled">
                <li>
                  <Link to={"/about/"}>About</Link>
                </li>
                <li className="has-children">
                  <span
                    className="menuDropdownLink"
                    onClick={this.toggleBusiness}
                  >
                    Business
                  </span>
                </li>
                <li className="has-children">
                  <span
                    className="menuDropdownLink"
                    onClick={this.togglePersonal}
                  >
                    Personal
                  </span>
                </li>
                <li>
                  <Link to={"/posts/"}>Posts</Link>
                </li>
                <li>
                  <Link to={"/contact/"}>Contact</Link>
                </li>
                <li className={styles.menuButton}>
                  <a
                      href="https://mohragency.epaypolicy.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button button-secondary"
                    >
                        Pay Online
                        </a>
                </li>
                <li className={styles.menuButton}>
                  <Link className="button" to={"/get-insurance/"}>
                    Get a Quote
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {this.state.show === "business" && <BusinessDropdown />}
        {this.state.show === "personal" && <PersonalDropdown />}
      </div>
    );
  }
}

class BusinessDropdown extends React.Component {
  render() {
    return <NavDropdownBusiness />;
  }
}
class PersonalDropdown extends React.Component {
  render() {
    return <NavDropdownPersonal />;
  }
}

export default Header;
