import React from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image";
import styles from "./logo.module.css"

const Logo = () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "lewis-mohr-wordmark-logo.png" }) {
          childImageSharp {
            fluid(maxHeight: 55) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        } 
      }
    `}
    render={data => (
      <Link to={'/'}><Img fluid={data.logo.childImageSharp.fluid} title="Lewis Mohr Insurance Agency"
      alt="Lewis Mohr Insurance Agency Logo" className={styles.logo}/></Link>
    )}
  />
);

export default Logo;