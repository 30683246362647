import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styles from "./navDropdown.module.css";

class NavDropdownBusiness extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.dropdownContainer}>
          <div className="grid grid-full">
            <div className="grid-cell u-med-1of3 u-large-1of4">
              <div className={styles.leftSide}>
                <StaticQuery
                  query={graphql`
                    query {
                      logo: file(relativePath: { eq: "contact-bg.jpg" }) {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                  `}
                  render={(data) => (
                    <Link to={"/baton-rouge-business-insurance/"}>
                      <Img
                        fluid={data.logo.childImageSharp.fluid}
                        title="Lewis Mohr Insurance Agency"
                        alt="Lewis Mohr Business Insurance Agency"
                        className="u-small-hide"
                        loading="eager"
                      />
                    </Link>
                  )}
                />
                <Link to={"/baton-rouge-business-insurance/"}>
                  <h6 className={styles.dropdownOverview}>
                    Business Insurance Overview
                  </h6>
                </Link>
                <p className="u-small-hide">
                  Learn more about what Lewis Mohr Insurance Agency, a
                  locally-owned, independent insurance agency, can offer for
                  your Louisiana based business.
                </p>
                <Link
                  to={"/baton-rouge-business-insurance/"}
                  className="button button-secondary"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className="grid grid-1of2 large-grid-1of3 xl-grid-1of4 u-med-2of3 u-large-3of4">
              <div className="grid-cell">
                <h6>For Your Operations</h6>
                <div className={styles.navSectionsDivider}></div>
                <ul className="list-unstyled">
                  <li>
                    <Link to={"/business-interruption-insurance/"}>
                      Business Interruption
                    </Link>
                  </li>
                  <li>
                    <Link to={"/cyber-liability-insurance/"}>
                      Cyber Liability
                    </Link>
                  </li>
                  <li>
                    <Link to={"/general-liability-insurance/"}>
                      General Liability
                    </Link>
                  </li>
                  <li>
                    <Link to={"/professional-liability-insurance/"}>
                      Professional Liability
                    </Link>
                  </li>
                  <li>
                    <Link to={"/small-business-owners-policy-bop/"}>
                      Small Business Owners Policy (BOP)
                    </Link>
                  </li>
                  <li>
                    <Link to={"/umbrella-excess-insurance/"}>
                      Umbrella and Excess
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="grid-cell">
                <h6>For Your Property</h6>
                <div className={styles.navSectionsDivider}></div>
                <ul className="list-unstyled">
                  <li>
                    <Link to={"/commercial-auto-insurance/"}>
                      Commercial Auto
                    </Link>
                  </li>
                  <li>
                    <Link to={"/commercial-drone-insurance/"}>
                      Commercial Drone
                    </Link>
                  </li>
                  <li>
                    <Link to={"/commercial-flood-insurance/"}>
                      Commercial Flood
                    </Link>
                  </li>
                  <li>
                    <Link to={"/commercial-property-insurance/"}>
                      Commercial Property
                    </Link>
                  </li>
                  <li>
                    <Link to={"/equipment-breakdown-insurance/"}>
                      Equipment Breakdown
                    </Link>
                  </li>
                  <li>
                    <Link to={"/inland-marine-insurance/"}>Inland Marine</Link>
                  </li>
                </ul>
              </div>
              <div className="grid-cell">
                <h6>For Your Employees</h6>
                <div className={styles.navSectionsDivider}></div>
                <ul className="list-unstyled">
                  <li>
                    <Link to={"/directors-officers-liability-insurance/"}>
                      Directors & Officers
                    </Link>
                  </li>
                  <li>
                    <Link to={"/group-health-insurance/"}>
                      Group Health Insurance
                    </Link>
                  </li>
                  <li>
                    <Link to={"/group-life-insurance/"}>
                      Group Life Insurance
                    </Link>
                  </li>
                  <li>
                    <Link to={"/workers-compensation/"}>
                      Workers Compensation
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="grid-cell">
                <h6>Business Resources</h6>
                <div className={styles.navSectionsDivider}></div>
                <ul className="list-unstyled">
                  <li>
                    <Link to={"/storm/"}>
                      Louisiana Hurricane and Storm Resources
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        "/posts/a-winter-weather-guide-for-louisiana-businesses-frozen-pipes-icy-conditions/"
                      }
                    >
                      Winter Weather Guide
                    </Link>
                  </li>
                  <li>
                    <Link to={"/workers-comp-coverage-check/"}>
                      Workers Comp Coverage Check
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dropdownOutside} />
      </div>
    );
  }
}
export default NavDropdownBusiness;
