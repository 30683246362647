import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styles from "./navDropdown.module.css";

class NavDropdownBusiness extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.dropdownContainer}>
          <div className="grid grid-full">
            <div className="grid-cell u-med-1of3 u-large-1of4">
              <div className={styles.leftSide}>
                <StaticQuery
                  query={graphql`
                    query {
                      logo: file(
                        relativePath: { eq: "nav-personal-dropdown.jpg" }
                      ) {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                  `}
                  render={(data) => (
                    <Link to={"/baton-rouge-personal-insurance/"}>
                      <Img
                        fluid={data.logo.childImageSharp.fluid}
                        title="Lewis Mohr Insurance Agency"
                        alt="Lewis Mohr Personal Insurance Agency"
                        className="u-small-hide"
                        loading="eager"
                      />
                    </Link>
                  )}
                />
                <Link to={"/baton-rouge-personal-insurance/"}>
                  <h6 className={styles.dropdownOverview}>
                    Personal Insurance Overview
                  </h6>
                </Link>
                <p className="u-small-hide">
                  Learn more about what Lewis Mohr Insurance Agency, a
                  family-owned, independent insurance agency, can offer for you
                  and your family.
                </p>
                <Link
                  to={"/baton-rouge-personal-insurance/"}
                  className="button button-secondary"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className="grid grid-1of2 large-grid-1of3 xl-grid-1of4 u-med-2of3 u-large-3of4">
              <div className="grid-cell">
                <h6>For You & Your Family</h6>
                <div className={styles.navSectionsDivider}></div>
                <ul className="list-unstyled">
                  <li>
                    <Link to={"/atv-insurance/"}>ATV Insurance</Link>
                  </li>
                  <li>
                    <Link to={"/auto-insurance/"}>Auto Insurance</Link>
                  </li>
                  <li>
                    <Link to={"/boat-watercraft-insurance/"}>
                      Boat & Watercraft Insurance
                    </Link>
                  </li>
                  <li>
                    <Link to={"/flood-insurance/"}>Flood Insurance</Link>
                  </li>
                  <li>
                    <Link to={"/homeowners-insurance/"}>
                      Homeowners Insurance
                    </Link>
                  </li>
                  <li>
                    <Link to={"/life-insurance/"}>Life Insurance</Link>
                  </li>
                  <li>
                    <Link to={"/motorcycle-insurance/"}>
                      Motorcycle Insurance
                    </Link>
                  </li>
                  <li>
                    <Link to={"/rv-insurance/"}>RV Insurance</Link>
                  </li>
                </ul>
              </div>
              <div className="grid-cell">
                <h6>Resources</h6>
                <div className={styles.navSectionsDivider}></div>
                <ul className="list-unstyled">
                  <li>
                    <Link to={"/storm/"}>
                      Louisiana Hurricane and Storm Resources
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/posts/how-to-file-a-homeowners-insurance-claim/"}
                    >
                      How to File a Homeowners Insurance Claim
                    </Link>
                  </li>
                  <li>
                    <Link to={"/posts/how-to-create-a-home-inventory/"}>
                      Home Inventory Downloadable Template
                    </Link>
                  </li>
                  <li>
                    <Link to={"/posts/reasons-to-choose-an-independent-agent/"}>
                      Why Choose an Independent Insurance Agent
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        "/posts/how-to-use-a-password-manager-to-protect-your-data/"
                      }
                    >
                      How to Use a Password Manager
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dropdownOutside} />
      </div>
    );
  }
}
export default NavDropdownBusiness;
