import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styles from "./footer.module.css";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        icon3: file(relativePath: { eq: "footer-bbb-logo.png" }) {
          childImageSharp {
            fixed(height: 40) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        icon4: file(
          relativePath: { eq: "footer-award-trusted-choice-insurance-logo.png" }
        ) {
          childImageSharp {
            fixed(height: 40) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        icon5: file(
          relativePath: { eq: "footer-award-business-ethics-logo.png" }
        ) {
          childImageSharp {
            fixed(height: 40) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        icon6: file(relativePath: { eq: "footer-award-bpa-logo.png" }) {
          childImageSharp {
            fixed(height: 40) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        youtube: file(relativePath: { eq: "social-icons/youtube.png" }) {
          childImageSharp {
            fixed(height: 25) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        facebook: file(relativePath: { eq: "social-icons/facebook.png" }) {
          childImageSharp {
            fixed(height: 25) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        instagram: file(relativePath: { eq: "social-icons/instagram.png" }) {
          childImageSharp {
            fixed(height: 25) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        linkedin: file(relativePath: { eq: "social-icons/linkedin.png" }) {
          childImageSharp {
            fixed(height: 25) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <footer className="site-footer">
        <div className={styles.footer}>
          <div className="grid grid-justifyCenter">
            <div className="grid-cell u-med-3of4">
              <div className="grid grid-full med-grid-fit grid-gutters">
                <div className="grid-cell u-med-1of2">
                  <h6 className={styles.footerHeader}>
                    Business Insurance Offerings
                  </h6>
                  <div className="grid">
                    <div className="grid-cell">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={"/business-interruption-insurance/"}>
                            Business Interruption
                          </Link>
                        </li>
                        <li>
                          <Link to={"/commercial-auto-insurance/"}>
                            Commercial Auto
                          </Link>
                        </li>
                        <li>
                          <Link to={"/commercial-flood-insurance/"}>
                            Commercial Flood
                          </Link>
                        </li>
                        <li>
                          <Link to={"/commercial-property-insurance/"}>
                            Commercial Property
                          </Link>
                        </li>
                        <li>
                          <Link to={"/cyber-liability-insurance/"}>
                            Cyber Liability
                          </Link>
                        </li>
                        <li>
                          <Link to={"/directors-officers-liability-insurance/"}>
                            D&amp;O Liability
                          </Link>
                        </li>
                        <li>
                          <Link to={"/commercial-drone-insurance/"}>
                            Drone Insurance
                          </Link>
                        </li>
                        <li>
                          <Link to={"/equipment-breakdown-insurance/"}>
                            Equipment Breakdown
                          </Link>
                        </li>
                        <li>
                          <Link to={"/general-liability-insurance/"}>
                            General Liability
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="grid-cell">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={"/group-health-insurance/"}>
                            Group Health Insurance
                          </Link>
                        </li>
                        <li>
                          <Link to={"/group-life-insurance/"}>
                            Group Life Insurance
                          </Link>
                        </li>
                        <li>
                          <Link to={"/inland-marine-insurance/"}>
                            Inland Marine
                          </Link>
                        </li>
                        <li>
                          <Link to={"/professional-liability-insurance/"}>
                            Professional Liability
                          </Link>
                        </li>
                        <li>
                          <Link to={"/small-business-owners-policy-bop/"}>
                            Small Business Owners Policy (BOP)
                          </Link>
                        </li>
                        <li>
                          <Link to={"/umbrella-excess-insurance/"}>
                            Umbrella and Excess
                          </Link>
                        </li>
                        <li>
                          <Link to={"/workers-compensation/"}>
                            Workers Compensation
                          </Link>
                        </li>
                        <li>
                          <Link to={"/workers-comp-coverage-check/"}>
                            Workers Comp Coverage Check
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="grid-cell u-med-1of4">
                  <h6 className={styles.footerHeader}>
                    Personal Insurance Offerings
                  </h6>
                  <ul className="list-unstyled">
                    <li>
                      <Link to={"/atv-insurance/"}>ATV Insurance</Link>
                    </li>
                    <li>
                      <Link to={"/auto-insurance/"}>Auto Insurance</Link>
                    </li>
                    <li>
                      <Link to={"/boat-watercraft-insurance/"}>
                        Boat &amp; Watercraft
                      </Link>
                    </li>
                    <li>
                      <Link to={"/flood-insurance/"}>Flood Insurance</Link>
                    </li>
                    <li>
                      <Link to={"/homeowners-insurance/"}>
                        Homeowners Insurance
                      </Link>
                    </li>
                    <li>
                      <Link to={"/life-insurance/"}>Life Insurance</Link>
                    </li>
                    <li>
                      <Link to={"/motorcycle-insurance/"}>
                        Motorcycle Insurance
                      </Link>
                    </li>
                    <li>
                      <Link to={"/rv-insurance/"}>RV Insurance</Link>
                    </li>
                  </ul>
                </div>
                <div className="grid-cell u-med-1of4">
                  <h6 className={styles.footerHeader}>Contact Us</h6>
                  <ul className="list-unstyled">
                    <li className={styles.subheader}>
                      <strong>Phone Number</strong>
                    </li>
                    <li className={styles.subcontent}>
                      <a href="tel:2252931086">225-293-1086</a>
                    </li>
                    <li className={styles.subheader}>
                      <strong className={styles.subheader}>
                        <div>Address</div>
                      </strong>
                    </li>
                    <li className={styles.subcontent}>
                      <a
                        href="https://goo.gl/maps/pNqYKsofVs22"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        11051 Coursey Blvd.
                        <br />
                        Baton Rouge, Louisiana 70816
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="grid grid-full med-grid-fit grid-gutters">
                <div className="grid-cell u-large-2of3">
                  <div>
                    <a
                      href="https://www.bbb.org/us/la/baton-rouge/profile/insurance-agency/lewis-mohr-real-estate-insurance-agency-llc-0835-24000056"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        className={styles.logo}
                        fixed={data.icon3.childImageSharp.fixed}
                        alt="BBB Profile Link"
                      />
                    </a>
                    <Img
                      className={styles.logo}
                      fixed={data.icon4.childImageSharp.fixed}
                      alt="Trusted Choice Insurance Logo"
                    />
                    <Img
                      className={styles.logo}
                      fixed={data.icon5.childImageSharp.fixed}
                      alt="Business Torch of Ethics Award"
                    />
                    <Img
                      className={styles.logo}
                      fixed={data.icon6.childImageSharp.fixed}
                      alt="Best Practices Insurance Agency Award"
                    />
                  </div>
                  <div className={styles.bottomLinks}>
                    <p className={styles.small}>
                      *Lewis Mohr does not warrant the accuracy, completeness,
                      or usefulness of the information presented on this page
                      and it is solely for general information purposes. For
                      more information, please read our full{" "}
                      <Link to={"/terms-of-use/"}>Terms of Use</Link> policy or
                      talk to your insurance agent directly.
                    </p>
                    <Link className={styles.bottomLink} to={"/privacy-policy/"}>
                      Privacy Policy
                    </Link>
                    <Link className={styles.bottomLink} to={"/terms-of-use/"}>
                      Terms of Use
                    </Link>
                  </div>
                </div>
                <div className="grid-cell u-large-1of3">
                  <div className={styles.rightLinks}>
                    <a
                      href="https://www.facebook.com/Lewis-Mohr-Real-Estate-Insurance-Agency-LLC-167555536614076/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        className={styles.logo}
                        fixed={data.facebook.childImageSharp.fixed}
                        alt="Lewis Mohr Insurance Agency Facebook Profile Link"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/lewismohrinsurance/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        className={styles.logo}
                        fixed={data.instagram.childImageSharp.fixed}
                        alt="Lewis Mohr Insurance Agency Instagram Profile Link"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/lewis-mohr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        className={styles.logo}
                        fixed={data.linkedin.childImageSharp.fixed}
                        alt="Lewis Mohr Insurance Agency LinkedIn Profile Link"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCSyzf1-pVB92xnmyZ20P23A/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        className={styles.logo}
                        fixed={data.youtube.childImageSharp.fixed}
                        alt="Lewis Mohr Insurance Agency YouTube Channel Link"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )}
  />
);

export default Footer;
